import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import Layout from "../components/layout";
import { ContactModal } from "../components/contactModal";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "2rem",
    },
    paragraph: {
        margin: "1rem auto",
        [theme.breakpoints.up("lg")]: {
            maxWidth: "70vw",
        },
    },
    imageContainer: {
        textAlign: "center",
    },
    image: {
        width: "90%",
        margin: "auto",
    },
    button: {
        marginRight: "1rem",
        fontWeight: "bold",
    },
}));

export default function Sell() {
    const classes = useStyles();
    return (
        <Layout>
            <Grid container alignItems="center" className={classes.container}>
                <Grid
                    container
                    item
                    sm={12}
                    md={6}
                    className={classes.imageContainer}
                >
                    <img
                        src="/sell_house.svg"
                        alt="sell_house"
                        className={classes.image}
                    />
                </Grid>
                <Grid container item sm={12} md={6}>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h3">
                            Getting the Right Value
                        </Typography>
                    </Grid>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h4">
                            Things to consider when Selling
                        </Typography>
                        <Typography>
                            Selling your home can be surprisingly time-consuming
                            and emotionally challenging, especially if you’ve
                            never done it before. At times it may feel like an
                            invasion of privacy because strangers will come into
                            your home, open your closets and cabinets, and poke
                            around. With no experience and a complex, emotional
                            transaction on your hands, it’s easy for first-time
                            home sellers to make lots of mistakes. Any smart
                            buyer will negotiate, and if you want to complete
                            the sale, you may have to play ball. Most people
                            want to list their homes at a price that will
                            attract buyers while still leaving some breathing
                            room for negotiations—the opposite of the
                            underpricing strategy described above.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h4">How I can Help</Typography>
                        <Typography>
                            A good agent generally has your best interests at
                            heart. I will help you set a fair and competitive
                            selling price for your home, increasing your odds of
                            a quick sale. I will also help tone down the emotion
                            of the process by interacting with potential buyers
                            and eliminating tire kickers who only want to look
                            at your property but have no intention of making an
                            offer.I have tons of experience negotiating home
                            sales, helping you get more money than you could on
                            your own. And if any problems crop up during the
                            process—and they commonly do—an I will be there to
                            handle them for you. Finally, I am familiar with all
                            the paperwork and pitfalls involved in real estate
                            transactions and can help make sure the process goes
                            smoothly. This means there won’t be any delays or
                            glitches in the deal.
                        </Typography>
                        <Typography>
                            For your property, I will: <br />
                            &#8226; Go through all the recent sales in your area
                            <br />
                            &#8226; Compare your home to those that were sold or
                            listed in the area <br />
                            &#8226; Provide you with a very accurate range your
                            home will sell for <br />
                            &#8226; Present the evaluation to you either in
                            person or by email <br />
                        </Typography>
                        <Typography>
                            Set up a session with me today for a
                            <strong> free evaluation </strong> of your property
                            !!!
                        </Typography>
                    </Grid>
                    <Grid container item className={classes.paragraph}>
                        <ContactModal></ContactModal>
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    );
}
